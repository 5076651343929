import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import Input from '@/components/Input';
import Select from 'react-select';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { assignLenders, createLenderTerms, fetchLenders, fetchOptions } from '@/api/lendersApi';
import Button from '@/components/Button';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { setBulkUserTransferLimit } from '@/api/walletApi';
import { fetchUserOptions } from '@/api/userApi';
import CurrencyyInput from '@/components/CurrencyyInput';

const schema = yup.object({
  userIds: yup
    .array()
    .min(1, 'Please select at least one user')
    .required('Please select a user')
});

export const SetUsersBulkLimitModal = ({ showModal, setShowModal }) => {
  const PAGE_LIMIT = 1500;
  const queryClient = useQueryClient();
  const [amount, setAmount] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleBulkLimit = useMutation({
    mutationFn: (data) => setBulkUserTransferLimit(data),
    onSuccess: () => {
      setAmount(0);
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('lenders');
      toast.success('Transfer limit assigned successfully');
      setShowModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

   const handleChange = (value) => {
     setAmount(value);
   };

  const users = useQuery({
    queryKey: ['users', {page: 1, limit: PAGE_LIMIT}],
    queryFn: fetchUserOptions
  });

  const user = users?.data?.data?.users;

  const userOptions =
    user?.map((item) => ({
      value: item?.id,
      label: item?.companies[0]?.name
    })) || [];

  const onSubmit = (data) => {
    const formData = {
      userIds: Array.isArray(data.users) ? data.users.map((item) => item.value) : [],
      amount: amount.toString()
    };
    // console.log(formData);
    handleBulkLimit.mutate(formData);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <CreateTermsView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}>
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            <Content>
              <div className="header">
                <CloseIcon
                  onClick={() => {
                    reset();
                    setAmount(0);
                    setShowModal(false);
                  }}
                />
              </div>

              <FormView onSubmit={handleSubmit(onSubmit)}>
                <Title>Select Users to Assign Transfer Limit</Title>

                {/* Multi-Select Dropdown */}

                <Section>
                  <InputView>
                    <LabelView>
                      <label>Amount</label>
                    </LabelView>

                    <CurrencyyInput
                      name="amount"
                      value={amount}
                      className="input"
                      placeholder="Enter amount"
                      onValueChange={(value, name) => handleChange(value, 'amount')}
                    />
                  </InputView>
                <DataView>
                </DataView>
                  <Label>Select a User</Label>
                  <Controller
                    name="userIds"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={userOptions}
                        isMulti
                        isSearchable
                        placeholder="Search Users"
                        menuShouldScrollIntoView={false}
                        menuPlacement="auto"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '60px',
                            borderColor: '#EFF0F7',
                            borderRadius: '5px',
                            border: '1px solid #EFF0F7',
                            backgroundColor: '#EFF0F7',
                            padding: '4px'
                          }),
                          menu: (base) => ({
                            ...base,
                            maxHeight: '200px',
                            overflowY: 'auto'
                          }),
                          menuList: (base) => ({
                            ...base,
                            maxHeight: '200px',
                            overflowY: 'auto'
                          }),
                          option: (base, { isFocused, isSelected }) => ({
                            ...base,
                            backgroundColor: isFocused
                              ? '#EFF0F7'
                              : isSelected
                                ? '#d3e0ea'
                                : 'white',
                            color: isFocused || isSelected ? '#000' : '#333',
                            cursor: 'pointer'
                          })
                        }}
                      />
                    )}
                  />
                  <Error>{errors.userIds?.message}</Error>
                </Section>

                <div className="btn">
                  <Button className="approve" type="submit">
                    {handleBulkLimit.isLoading ? (
                      <Oval height={30} width={30} color="#fff" secondaryColor="#ddd" />
                    ) : (
                      'Set Limit'
                    )}
                  </Button>
                </div>
              </FormView>
            </Content>
          </motion.div>
        </CreateTermsView>
      )}
    </AnimatePresence>
  );
};

const CreateTermsView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    padding: 20px;
    border-radius: 8px;
    min-width: 40vw;
    /* min-height: calc(100vh - 100px); */
  }
`;

const FormView = styled.form`
  width: 500px;
  margin-top: 30px;
  margin-bottom: 50px;

  .btn {
    margin-top: 40px;

    .approve {
      width: 40%;
      border-radius: 18px;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  justify-content: center; */
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    svg {
      cursor: pointer;
    }
  }
`;

const Title = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Section = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 1rem;
`;

const Options = styled.div`
  display: flex;
  gap: 1rem;
`;

const Option = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
`;

const OptionLabel = styled.label`
  /* width: 100%; */
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Radio = styled.input`
  /* width: 20%; */
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #27ae60;
  }
`;

const Error = styled.p`
  font-size: 0.875rem;
  color: #e74c3c;
  margin-top: 0.25rem;
`;

const DataView = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: ${({ theme }) => theme.colors?.white};

  .disabled {
    background-color: #f2f2f2;
    color: #999999;
  }

  .check {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 0;
    gap: 1rem;

    .radio-input {
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  position: relative;

  .input::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    /* margin: 0; */
  }

  .currency-input {
    width: 100%;
    height: 64px;
    outline: none;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 300;
    text-indent: 15px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

    &:focus {
      background-color: ${({ theme }) => theme.colors?.white};
      border: 1px solid ${({ theme }) => theme.colors?.activeTitle};
    }

    &:disabled {
      background-color: #f2f2f2;
      color: #999999;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 1rem;
      font-weight: 300;
    }
  }

  select {
    padding: 0 1rem;
    margin-top: 5px;
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    border: none;
    outline: none;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
    padding: 0 20px;
    /* font-weight: 600; */
    background-color: ${({ theme }) => theme.colors?.inputBackground};
  }

  .icon {
    background-color: transparent;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    position: absolute;
    top: 2.85rem;
    right: 12px;
    cursor: pointer;

    svg {
      transition: all 0.3s ease-in-out;
      transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
      transform-origin: 50% 50%;
      width: 20px;
      height: 15px;
    }
  }

  .input {
    position: relative;

    button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }

  .error-label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error_200};
    margin-top: 5px;
  }
`;

const LabelView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;

  label {
    font-size: 1rem;
  }
`;
