import { fetchUserVirtualAccount } from '@/api/virtualAccountsApi';
import PageHeader from '@/components/PageHeader';
import PageLoader from '@/loaders/PageLoader';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { formatInTimeZone } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { numberWithCommas } from '@/utils/helpers';

// const UserVirtualAccountDetails = () => {
//   const navigate = useNavigate();
//   const { search } = useLocation();
//   const { id } = useParams();

//   const userVirtualAccount = useQuery({
//     queryKey: ['userVirtualAccount', { id }],
//     queryFn: fetchUserVirtualAccount
//   });

//   const [currentDetailPage, setCurrentDetailPage] = useState(1);

//   const params = new URLSearchParams(search);
//   const currentPage = params.get('page');

//   const details = userVirtualAccount?.data?.data;

//   const handleReturn = () => {
//     window.history.back();
//   };

//   useEffect(() => {
//     if (currentPage) {
//       setCurrentDetailPage(parseInt(currentPage));
//     }
//   }, [currentPage]);

//   if (userVirtualAccount.isFetching) {
//     return <PageLoader />;
//   }
//   return (
//     <>
//       <PageHeader title="User Virtual Accounts Details" subtitle="View and manage virtual accounts" />
//       <UserVirtualAccountDetailsView>
//         <div className="return" onClick={handleReturn}>
//           <BackIcon />
//         </div>
//         <DataView>
//           <FormData>
//             {details.accounts.map((detail) => {
//               return (
//                 <div>
//                   {/* <div className="item">
//                     <div className="label-view">
//                       <h3>Account Status</h3>
//                       <Status status={details?.status}>{details?.status || '--'}</Status>
//                     </div>
//                   </div> */}
//                   <div className="item">
//                     <div className="label-view">
//                       <h3>Account Name</h3>
//                       <p>{detail?.accountName || '--'}</p>
//                     </div>
//                   </div>
//                   <div className="item">
//                     <div className="label-view">
//                       <h3>Account Number</h3>
//                       <p>{detail?.accountNumber || '--'}</p>
//                     </div>
//                   </div>
//                   <div className="item">
//                     <div className="label-view">
//                       <h3>Bank</h3>
//                       <p className="name">{detail?.bank || '--'}</p>
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//             {/* <div className="item">
//               <div className="label-view">
//                 <h3>Date Created</h3>
//                 <p>
//                   {formatInTimeZone(new Date(details?.createdAt), 'UTC', 'do MMM, yyy, h:mm aa') ||
//                     '--'}
//                 </p>
//               </div>
//             </div>
//             <div className="item">
//               <div className="label-view">
//                 <h3>Date Updated</h3>
//                 <p>
//                   {formatInTimeZone(new Date(details?.updatedAt), 'UTC', 'do MMM, yyy, h:mm aa') ||
//                     '--'}
//                 </p>
//               </div>
//             </div> */}
//           </FormData>

//           <FormData>
//             <div className="item">
//               <div className="label-view">
//                 <h3>User Id</h3>
//                 <p>{details?.user?.id}</p>
//               </div>
//               <div className="copy-view">
//                 <button
//                   onClick={() => {
//                     navigate(`/users/${details?.user?.id}`);
//                   }}>
//                   View <ExtLinkIcon />
//                 </button>
//               </div>
//             </div>
//             <div className="item">
//               <div className="label-view">
//                 <h3>Fullname</h3>
//                 <p>{`${details?.user?.firstName} ${details?.user?.lastName}`}</p>
//               </div>
//             </div>
//             <div className="item">
//               <div className="label-view">
//                 <h3>Email Address</h3>
//                 <p>{details?.user?.email}</p>
//               </div>
//             </div>
//             <div className="item">
//               <div className="label-view">
//                 <h3>Company Name</h3>
//                 <p>{details?.company?.name}</p>
//               </div>
//             </div>
//             <div className="item">
//               <div className="label-view">
//                 <h3>Date Created</h3>
//                 <p>
//                   {formatInTimeZone(
//                     new Date(details?.company?.createdAt),
//                     'UTC',
//                     'do MMM, yyy, h:mm aa'
//                   ) || '--'}
//                 </p>
//               </div>
//             </div>
//           </FormData>
//         </DataView>
//       </UserVirtualAccountDetailsView>
//     </>
//   );
// };

const UserVirtualAccountDetails = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();

  const userVirtualAccount = useQuery({
    queryKey: ['userVirtualAccount', { id }],
    queryFn: fetchUserVirtualAccount
  });

  if (userVirtualAccount.isFetching) {
    return <PageLoader />;
  }

  const details = userVirtualAccount?.data?.data;

  const handleReturn = () => {
    window.history.back();
  };

  return (
    <>
      <PageHeader
        title="User Virtual Account Details"
        subtitle="View and manage virtual accounts"
      />
      <UserVirtualAccountDetailsView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>

        <div className="user-info">
          <h1>{`${details?.user?.firstName || '-'} ${details?.user?.lastName || '-'}`}</h1>
          <div className="info-grid">
            <div className>
              <div>
                <h4>User ID</h4>
                <p>{details?.user?.id || '--'}</p>
              </div>
            </div>
            <div>
              <h4>Email Address</h4>
              <p>{details?.user?.email || '--'}</p>
            </div>
            <div>
              <h4>Company Name</h4>
              <p>{details?.company?.name || '--'}</p>
            </div>
            <div>
              <h4>Balance</h4>
              <p className="balance">₦{numberWithCommas(details?.walletBalance || '00.00')}</p>
            </div>
          </div>
        </div>

        <div className="accounts-grid">
          {details?.accounts?.map((account) => (
            <div className="account-card" key={account?.accountNumber}>
              <p>Account Name</p>
              <h3>{account?.accountName || '-'}</h3>
              <div className="details">
                <p>{account?.bank || '-'}</p>
                <span>({account?.accountNumber || '-'})</span>
              </div>
            </div>
          ))}
        </div>
      </UserVirtualAccountDetailsView>
    </>
  );
};


export default UserVirtualAccountDetails;

// const UserVirtualAccountDetailsView = styled.div`
//   width: 82%;
//   padding: 30px;
//   background-color: ${({ theme }) => theme.colors.white};
//   overflow-y: scroll;
//   height: 100%;
//   padding-top: 120px;
//   overflow-x: hidden;
//   margin-left: 18%;

//   .return {
//     width: 30px;
//     margin-bottom: 20px;
//     cursor: pointer;
//   }
// `;

// const Status = styled.p`
//   color: ${({ status }) => {
//     switch (status) {
//       case 'active':
//         return '#16A34A';
//       case 'pending':
//         return '#F4B740';
//       case 'processing':
//         return '#F4B740';
//       case 'initiated':
//         return '#F4B740';
//       case 'pending':
//         return '#F4B740';
//       case 'inactive':
//         return '#B91C1C';
//       case 'declined':
//         return '#B91C1C';

//       default:
//     }
//   }};
//   text-transform: capitalize;
// `;

// const DataView = styled.div`
//   width: 100%;
//   background-color: ${({ theme }) => theme.colors?.white};
//   display: flex;
//   justify-content: space-between;
//   gap: 1rem;
// `;

// const FormData = styled.div`
//   height: 100%;
//   width: 50%;
//   background-color: ${({ theme }) => theme.colors?.white};
//   border-radius: 6px;
//   padding: 20px;
//   border: 1px solid ${({ theme }) => theme.colors?.line};

//   .item {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 20px 0;
//     border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

//     .label-view {
//       width: 70%;
//       display: flex;
//       justify-content: center;
//       flex-direction: column;
//       color: ${({ theme }) => theme?.colors?.secondary};

//       h3 {
//         font-size: 0.875rem;
//         font-weight: 500;
//         color: ${({ theme }) => theme?.colors?.secondary};
//         margin-bottom: 8px;
//       }

//       p {
//         font-size: 0.875rem;
//         font-weight: 600;
//       }

//       .name {
//         text-transform: capitalize;
//       }
//     }

//     .copy-view {
//       width: 120px;
//       display: flex;
//       align-items: center;

//       button,
//       a {
//         width: 100%;
//         height: 40px;
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         color: ${({ theme }) => theme?.colors?.info};
//         font-size: 0.875rem;

//         svg {
//           margin-left: 5px;
//           width: 16px;
//           height: 16px;

//           path {
//             stroke: ${({ theme }) => theme?.colors?.info};
//           }
//         }
//       }
//     }
//   }

//   .last {
//     border-bottom: none;
//     padding-bottom: 0;
//   }
// `;


const UserVirtualAccountDetailsView = styled.div`
  width: 62%;
  padding: 30px;
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;
  background-color: ${({ theme }) => theme.colors.white};

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .user-info {
    padding: 20px 0;

    h1 {
      font-size: 28px;
      font-weight: 600;
      color: ${(props) => props.theme.colors?.secondary};
    }

    .info-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin-top: 15px;

      div {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid ${(props) => props.theme.colors?.line};

        h4 {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
          color: ${(props) => props.theme.colors?.secondary};
        }

        p {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 15px;
          color: ${(props) => props.theme.colors?.secondary};
        }

        .balance {
          font-size: 18px;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }
    }
  }

  .accounts-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 30px;

    .account-card {
      background: #f8f9fb;
      padding: 15px;
      border-radius: 8px;
      border: 1px solid 1px solid ${(props) => props.theme.colors?.line};

      h3 {
        font-size: 16px;
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
        padding: 10px 0;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => props.theme.colors?.secondary};
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => props.theme.colors?.secondary};
      }

      .details {
        padding: 20px 0;
        display: flex;
        border-top: 1px solid ${(props) => props.theme.colors?.line};

        p {
          font-weight: 600;
        }
      }
    }
  }
`;

// const Status = styled.p`
//   color: ${({ status }) => {
//     switch (status) {
//       case 'active':
//         return '#16A34A';
//       case 'pending':
//       case 'processing':
//       case 'initiated':
//         return '#F4B740';
//       case 'inactive':
//       case 'declined':
//         return '#B91C1C';
//       default:
//         return '#6B7280';
//     }
//   }};
//   font-weight: bold;
//   text-transform: capitalize;
// `;

// const DataView = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   gap: 1.5rem;
//   background-color: ${({ theme }) => theme.colors?.white};
//   padding: 20px;
// `;

// const FormData = styled.div`
//   background-color: ${({ theme }) => theme.colors?.white};
//   border-radius: 8px;
//   padding: 20px;
//   border: 1px solid ${({ theme }) => theme.colors?.line};
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);

//   .item {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 15px 0;
//     border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

//     .label-view {
//       width: 100%;
//       color: ${({ theme }) => theme?.colors?.secondary};

//       h3 {
//         font-size: 0.875rem;
//         font-weight: 500;
//         color: ${({ theme }) => theme?.colors?.secondary};
//         margin-bottom: 6px;
//       }

//       p {
//         font-size: 0.875rem;
//         font-weight: 600;
//       }

//       .name {
//         text-transform: capitalize;
//       }
//     }
//   }

//   .copy-view {
//     display: flex;
//     justify-content: flex-end;
//     margin-top: 10px;

//     button,
//     a {
//       display: flex;
//       align-items: center;
//       color: ${({ theme }) => theme?.colors?.info};
//       font-size: 0.875rem;
//       font-weight: 500;

//       svg {
//         margin-left: 5px;
//         width: 16px;
//         height: 16px;
//       }
//     }
//   }

//   .last {
//     border-bottom: none;
//     padding-bottom: 0;
//   }
// `;



// const UserVirtualAccountDetailsView = styled.div`
//   padding: 20px;
//   font-family: 'Inter', sans-serif;
  
//   .return {
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     margin-bottom: 20px;
//   }

 
// `;