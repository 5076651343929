import api from './api';

export const fetchCompliances = async ({ queryKey }) => {
  const [, { page, limit, search, orderBy, fetch }] = queryKey;
  let url = `/compliance?page=${page}&limit=${limit}`;
  
  if (search) {
    url += `&search=${search}`;
  }
  if (orderBy) {
    url += `&orderBy=${orderBy}`;
  }
  if (fetch) {
    url += `&fetch=${fetch}`;
  }

  const response = await api.get(url);
  return response.data;
};

export const fetchCompliance = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/compliance/single/${id}`);
  return response.data;
};

export const fetchComplianceVerification = async ({ queryKey }) => {
  const [, { id }] = queryKey;
  const response = await api.get(`/compliance/${id}/verifications`);
  return response.data;
};

export const approveOrRejectCompliance = async (id, data) => {
  const response = await api.post(`/compliance/${id}/approve-or-reject`, data);
  return response.data;
};
export const approveSelfieCompliance = async (id, data) => {
  const response = await api.patch(`/compliance/${id}/approve/selfie`, data);
  return response.data;
};

export const exportCompliance = async (data) => {
  const response = await api.post(`/export/compliance`, data);
  return response.data;
}

export const fetchComplianceMetrics = async ({ queryKey }) => {
  const [, { fetch, startDate, endDate }] = queryKey;

  // Build the query parameters
  let query = '';

  // Only add fetch if startDate and endDate are not provided
  if (!startDate && !endDate) {
    query += `fetch=${fetch}`;
  }

  // Add startDate and endDate to the query if provided
  if (startDate) {
    query += (query ? '&' : '') + `startDate=${startDate}`;
  }
  if (endDate) {
    query += (query ? '&' : '') + `endDate=${endDate}`;
  }

  const response = await api.get(`/compliance/metrics?${query}`);
  return response.data;
};