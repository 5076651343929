import api from "./api";

export const fetchTransferLimits = async () => {
  const response = await api.get(`/setting/daily-transfer-limit`);
  return response.data;
};

export const setDefaultTransferLimit = async (data) => {
  const response = await api.put(`/setting/daily-transfer-limit`, data);
  return response.data;
};
