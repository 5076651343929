import withAuthentication from '@/hooks/withAuthentication';
import React from 'react'
import styled from 'styled-components';
import NotificationsTable from './NotificationsTable';

const Notifications = () => {
  return (
    <NotificationsView>
      <TitleView>
        <h3>Announcements</h3>
        <p>
          Manage announcements by creating and updating announcement for certain purposes
        </p>
      </TitleView>
      <NotificationsTable />
    </NotificationsView>
  );
}

export default withAuthentication(Notifications);

const NotificationsView = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const TitleView = styled.div`
  margin-bottom: 30px;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeTitle};
    max-width: 50%;
  }
`;