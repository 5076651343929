import { fetchTerms } from '@/api/termsApi';
import Button from '@/components/Button';
import PageHeader from '@/components/PageHeader';
import withAuthentication from '@/hooks/withAuthentication';
import PageLoader from '@/loaders/PageLoader';
import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { AnimatePresence, motion } from 'framer-motion';
import CreateTermsModal from './CreateTermsModal';
import useOnClickOutside from '@/hooks/useClickOutside';
import UpdateTermsModal from './UpdateTermsModal';
import { usePermissions } from '@/hooks/usePermissions';
import toast from 'react-hot-toast';

const Terms = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canCreateTerm = hasPermission('settings', 'create');
  const canUpdateTerm = hasPermission('settings', 'update');

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const terms = useQuery({
    queryKey: ['terms'],
    queryFn: fetchTerms
  });

  const billTerms = terms?.data?.data;

  const handleCreate = () => {
    const isAllowed = canCreateTerm || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setShowCreateModal(!showCreateModal);
    }
  }

  const handleUpdate = (termType) => {
    const isAllowed = canUpdateTerm || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedItemType(termType);
      setShowUpdateModal(!showUpdateModal);
    }
  }

  if (terms.isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <TermsView>
        <DisplayView>
          <div className="layout">
            <div className="button-container">
              <Button
                width="22%"
                height="48px"
                type="button"
                onClick={() => handleCreate()}>
                Create Term
              </Button>
            </div>
            <div className="display">
              {billTerms?.map((term, i) => {
                return (
                  <div key={term.id} className="items">
                    <div className="plan">
                      <h3>{term.type.replace(/_/g, ' ')}</h3>
                      <div className="actions">
                        <button
                          className="menu-icon"
                          onClick={() => {
                            setShowMenu(true);
                            setCurrentIndex(i);
                          }}>
                          <MoreIcon />
                        </button>

                        <AnimatePresence>
                          {showMenu && currentIndex === i ? (
                            <motion.div
                              ref={ref}
                              className="menu-actions"
                              initial={{ y: -20 }}
                              animate={{ y: 0 }}
                              exit={{ y: -10, opacity: 0 }}
                              transition={{ duration: 0.2 }}>
                              <button
                                onClick={() => {
                                  // setSelectedItemType(term?.type);
                                  // setShowUpdateModal(true);
                                  handleUpdate(term?.type);
                                }}>
                                <span>Edit Term</span>
                              </button>
                            </motion.div>
                          ) : null}
                        </AnimatePresence>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </DisplayView>
      </TermsView>

      <CreateTermsModal showCreateModal={showCreateModal} setShowCreateModal={setShowCreateModal} />

      <UpdateTermsModal
        showUpdateModal={showUpdateModal}
        setShowUpdateModal={setShowUpdateModal}
        selectedItemType={selectedItemType}
      />
    </>
  );
};

export default withAuthentication(Terms);

const TermsView = styled.div`
  width: 100%;
`;

const DisplayView = styled.div`
  width: 100%;
  height: 100%;

  .layout {
    /* border: 1px solid #000; */
    padding: 20px 0;
    display: flex;
    flex-direction: column;

    .button-container {
      display: flex;
      justify-content: flex-end;
      padding: 0 20px;
      margin-bottom: 20px;

      button {
        margin-left: 20px;
        margin-right: 60px;
      }
    }

    .display {
      /* display: grid; */
      display: flex;
      /* grid-template-columns: repeat(2, 80%); */
      gap: 3rem;
      flex-wrap: wrap;
      /* justify-content: space-between; */
      align-items: center;
      padding: 0 20px;

      .items {
        width: 45%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid ${({ theme }) => theme.colors.line};

        .plan {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            width: 100%;
            font-size: 1rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.secondary};
          }

          .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            svg {
              cursor: pointer;

              path {
                stroke: ${({ theme }) => theme.colors?.secondary};
              }
            }

            .menu-actions {
              position: absolute;
              top: 22px;
              background-color: ${({ theme }) => theme.colors.white};
              box-shadow:
                0px 0px 2px rgba(125, 125, 125, 0.1),
                0px 2px 10px rgba(0, 0, 0, 0.1);
              border-radius: 10px;
              z-index: 10;
              width: 180px;
              border: none;

              button {
                width: 100%;
                padding: 16px 12px;
                font-size: 0.875rem;
                background-color: transparent;
                transition: all 0.2s ease-in-out;
                border: none;
                outline: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &:hover {
                  background-color: ${({ theme }) => theme.colors.layer3};
                }

                span {
                  color: ${({ theme }) => theme.colors.activeTitle};
                  width: calc(100% - 35px);
                  display: flex;
                  justify-content: flex-start;
                  text-align: left;
                  font-weight: 400;
                }

                .delete {
                  color: ${({ theme }) => theme.colors?.error_200};
                }
              }
            }
          }
        }
      }
    }
  }
`;
