import { fetchUser, fetchUserData } from '@/api/userApi';
import PageHeader from '@/components/PageHeader';
import PageLoader from '@/loaders/PageLoader';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from 'styled-components';
import UserMetrics from './components/UserMetrics';
import PropTypes from 'prop-types';

import { ReactComponent as CopyIcon } from '@/assets/icons/copy.icon.svg';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { toast } from 'react-hot-toast';
import withAuthentication from '@/hooks/withAuthentication';
import { useEffect, useState } from 'react';
import SubscribeModal from './components/SubscribeModal';
import { fetchSubscriptions } from '@/api/subscriptionApi';
import { usePermissions } from '@/hooks/usePermissions';
import WalletReconciliationModal from './components/WalletReconciliationModal';
import { fetchProfile } from '@/api/authApi';
import { UserTabs } from './components/UserTabs';

function User() {
  const { id } = useParams();
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [currentDetailPage, setCurrentDetailPage] = useState(1);

  const params = new URLSearchParams(search);
  const currentPage = params.get('page');

  const handleReturn = () => {
    // window.history.back();
    navigate(`/users?page=${currentPage}`);
  };

  useEffect(() => {
    if (currentPage) {
      setCurrentDetailPage(parseInt(currentPage));
    }
  }, [currentPage]);

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canUpdateUsers = hasPermission('users', 'update');

  const user = useQuery({
    queryKey: ['user', { id }],
    queryFn: fetchUser
  });

  const detail = user?.data?.data;

  const profile = useQuery({
    queryKey: ['profile'],
    queryFn: fetchProfile
  });

  const profileInfo = profile?.data?.data;

  const userData = useQuery({
    queryKey: ['userData', { id }],
    queryFn: fetchUserData
  });

  const subscriptions = useQuery({
    queryKey: ['subscriptions'],
    queryFn: fetchSubscriptions
  });

  const handleButton = () => {
    const isAllowed = canUpdateUsers || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setShowSubscriptionModal(true);
    }
  };

  // if (user.isFetching || userData.isFetching || subscriptions.isFetching) {
  //   return <PageLoader />;
  // }

  return (
    <>
      <PageHeader
        title="User details"
        // subtitle={`${detail?.firstName || ''} ${detail?.lastName || ''}`}
        subtitle="View and manage user details"
      />
      <UserView>
        <p className="return" onClick={handleReturn}>
          <BackIcon />
        </p>
        <UserMetrics userData={userData?.data?.data} />

        <ActionView>
          {/* {profileInfo?.isSuper === true && ( */}
          {/* {isASuperAdmin ? (
          ) : null} */}
            <button type="button" onClick={() => setShowWalletModal(!showWalletModal)}>
              Reconciliate Wallet
            </button>

          <button type="button" onClick={() => handleButton()}>
            Update Subscription
          </button>
        </ActionView>

        <UserTabs />
      </UserView>

      <SubscribeModal
        showModal={showSubscriptionModal}
        setShowModal={setShowSubscriptionModal}
        subscriptions={subscriptions?.data?.data}
        userId={id}
      />

      <WalletReconciliationModal
        showModal={showWalletModal}
        setShowModal={setShowWalletModal}
        item={detail}
      />
    </>
  );
}

export default withAuthentication(User);

User.propTypes = {
  setPage: PropTypes.func.isRequired,
  users: PropTypes.array // Add more propTypes as needed
};

const UserView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const ActionView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 60px;

  button {
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors?.secondary};
    color: ${({ theme }) => theme.colors?.white};
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 6px;
    padding: 0 20px;
    margin-left: 20px;
    cursor: pointer;
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .col2 {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: space-between; */
  }
`;

const FormData = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .head-title {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
    padding-bottom: 20px;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      .capitalize {
        text-transform: capitalize;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme?.colors?.activeTitle};
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const FormData2 = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};
  /* margin-bottom: 20px; */

  .head-title {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
    padding-bottom: 20px;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
      color: ${({ theme }) => theme?.colors?.secondary};
    }
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      .capitalize {
        text-transform: capitalize;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme?.colors?.activeTitle};
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
