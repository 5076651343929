import PageHeader from '@/components/PageHeader';
import { SettingsView, TabContent, TabView } from './settings.styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Accounts from './components/accounts/Accounts';
import withAuthentication from '@/hooks/withAuthentication';
import { useEffect } from 'react';
import Terms from './components/terms/Terms';
import { Modules } from './components/modules/Modules';
import { Roles } from './components/roles/Roles';
import { usePermissions } from '@/hooks/usePermissions';
import { EmptyState } from '@/components/EmptyState';
import Providers from './components/providers/Providers';
import TransferLimit from './components/transferLimit/TransferLimit';
import Notifications from './components/notifications/Notifications';

const tabs = [
  {
    name: 'Accounts',
    path: 'accounts',
  },
  {
    name: 'Terms',
    path: 'terms',
  },
  {
    name: 'Roles',
    path: 'roles',
  },
  {
    name: 'Providers',
    path: 'providers'
  },
  {
    name: 'Transfer Limit',
    path: 'transfer-limit'
  },
  {
    name: 'Announcements',
    path: 'announcements'
  }
];


function Settings() {
  const { role, hasPermission, superAdmin } = usePermissions();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=accounts');
      // Optionally, you can also navigate to the new URL
      // navigate('/settings?tab=accounts');
    }
  }, [searchParams, navigate, setSearchParams]);

  const canReadSettings = hasPermission('settings', 'read');
  const isASuperAdmin = superAdmin(true);
  const isRoleAdmin = role('Admin');
  
  return (
    <SettingsView>
      <PageHeader title="Settings" />
      {canReadSettings || isASuperAdmin || isRoleAdmin ? (
        <div>
          <TabView>
            {tabs.map((item) => (
              <button
                key={item.name}
                className={`tab ${tab === item.path ? 'active' : ''}`}
                onClick={() => setSearchParams(`tab=${item.path}`)}>
                {item.name}
              </button>
            ))}
          </TabView>

          <TabContent>
            {tab === 'accounts' && <Accounts />}
            {tab === 'terms' && <Terms />}
            {tab === 'roles' && <Roles />}
            {tab === 'providers' && <Providers />}
            {tab === 'transfer-limit' && <TransferLimit />}
            {tab === 'announcements' && <Notifications />}
          </TabContent>
        </div>
      ) : (
        <EmptyState />
      )}
    </SettingsView>
  );
}

export default withAuthentication(Settings);
