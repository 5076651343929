import { fetchTransactionFees } from '@/api/transactionApi';
import Button from '@/components/Button';
import useOnClickOutside from '@/hooks/useClickOutside';
import TableLoader from '@/loaders/TableLoader';
import { numberWithCommas } from '@/utils/helpers';
import { useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { usePermissions } from '@/hooks/usePermissions';
import toast from 'react-hot-toast';
// import UpdateTransactionFee from './UpdateTransactionFee';
import CreateUserChargeModal from './CreateUserChargeModal';
import { fetchUserCharges } from '@/api/userApi';
import { useSelector } from 'react-redux';
import UpdateUserCharge from './UpdateUserCharge';

const UserTransactionCharges = ({ user }) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const selected = useSelector((state) => state.app.selected)
  const id = selected?.id;

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canCreateFee = hasPermission('users', 'create');
  const canUpdateFee = hasPermission('users', 'update');


  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const userCharges = useQuery({
    queryKey: ['userCharges', { id: id }],
    queryFn: fetchUserCharges,
    keepPreviousData: true
  });

  const charges = userCharges.data?.data?.items;

  const handleCreate = () => {
    const isAllowed = canCreateFee || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setShowCreateModal(!showCreateModal);
    }
  };

  const handleUpdate = (feeId) => {
    const isAllowed = canUpdateFee || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setSelectedId(feeId);
      setShowUpdateModal(true);
    }
  };

  if (userCharges.isFetching) {
    return <TableLoader />;
  }

  return (
    <TransactionFeesView>
      <TableHeader>
        <Button
          width="20%"
          height="48px"
          type="button"
          onClick={() => {
            handleCreate();
          }}>
          Create Charge
        </Button>
      </TableHeader>
      <TableHead>
        <div>
          <span>Catergory</span>
        </div>
        <div>
          <span>Type</span>
        </div>
        <div>
          <span>Min. Amount</span>
        </div>
        <div>
          <span>Max. Amount</span>
        </div>
        <div>
          <span>Percentage</span>
        </div>
        <div>
          <span>Flat</span>
        </div>
        <div>
          <span>Status</span>
        </div>
        <div>
          <span>V.A.T</span>
        </div>
        <div></div>
      </TableHead>
      {/* {charges.length === 0 && (
      )} */}
        <TableBody>
          {charges?.map((charge, i) => {
            return (
              <div key={charge?.id} className="items">
                <div>
                  <span>{charge?.category}</span>
                </div>
                <div>
                  <span>{charge?.type}</span>
                </div>
                <div>
                  <span>₦{numberWithCommas(charge?.min)}</span>
                </div>
                <div>
                  <span>₦{numberWithCommas(charge?.max)}</span>
                </div>
                <div>
                  <span>{charge?.percentage}%</span>
                </div>
                <div>
                  <span>{charge.flat}</span>
                </div>
                <Status status={charge?.status}>
                  <span className="dot"></span> {charge?.status || '-'}
                </Status>
                <div>
                  <span>{charge?.vat || '-'}</span>
                </div>
                <div className="actions">
                  <button
                    className="menu-icon"
                    onClick={() => {
                      setShowMenu(true);
                      setCurrentIndex(i);
                    }}>
                    <MoreIcon />
                  </button>

                  <AnimatePresence>
                    {showMenu && currentIndex === i ? (
                      <motion.div
                        ref={ref}
                        className="menu-actions"
                        initial={{ y: -20 }}
                        animate={{ y: 0 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}>
                        {/* <button
                          onClick={() => {
                            handleUpdate(charge?.id);
                          }}>
                          <span>View Charge</span>
                        </button> */}
                        <button
                          onClick={() => {
                            handleUpdate(charge?.id);
                          }}>
                          <span>Update Charge</span>
                        </button>
                        {/* <button
                          onClick={() => {
                            setShowDeleteModal(true);
                            setSelectedSubscription(item);
                          }}>
                          <span className="delete">Delete Subscription</span>
                        </button> */}
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            );
          })}
        </TableBody>

      <CreateUserChargeModal
        id={id}
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
      />

      <UpdateUserCharge
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
      />
    </TransactionFeesView>
  );
};

export default UserTransactionCharges;

const TransactionFeesView = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 60px;
`;

const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return '#16A34A';
    case 'pending':
      return '#F4B740';
    case 'processing':
      return '#F4B740';
    case 'inactive':
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ status }) => getStatusColor(status)};
  }
`;

const TableHeader = styled.div`
  /* width: 100%; */
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-top: none;
  border-right: none;
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }
  }

  .plan {
    flex: 1.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .plan {
      flex: 1.5;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }

          .delete {
            color: ${({ theme }) => theme.colors?.error_200};
          }
        }
      }
    }
  }
`;
