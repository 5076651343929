import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.icon.svg';
import { formatInTimeZone } from 'date-fns-tz';

const VerificationDetailsModal = ({ showModal, setShowModal, selectedItem, bvnImage, complianceData }) => {
  console.log(selectedItem);
  console.log(complianceData);
  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            <ModalContent>
              <ContentView>
                <HeaderView>
                  <h3>Verification Job Details</h3>
                  <button className="close" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </HeaderView>

                <Content>
                  {selectedItem.type.includes('BVN') || selectedItem.type.includes('NIN') ? (
                    <DataView>
                      <FormData>
                        <div className="item">
                          <div className="label-view">
                            <h3>Job Id</h3>
                            <p>{selectedItem?.metadata?.result?.PartnerParams?.job_id || '--'}</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Verification Type</h3>
                            <p>{selectedItem?.type || '--'}</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Status</h3>
                            <Status status={selectedItem?.status}>
                              {selectedItem?.status || '--'}
                            </Status>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Fullname</h3>
                            {selectedItem?.metadata?.result?.PartnerParams?.FullName ? (
                              <p>
                                {selectedItem?.metadata?.result?.PartnerParams?.FullName || '--'}
                              </p>
                            ) : (
                              <p>
                                {`${complianceData?.user?.firstName} ${complianceData?.user?.lastName}`}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Firstname</h3>
                            {selectedItem?.metadata?.result?.PartnerParams?.FirstName ? (
                              <p>
                                {selectedItem?.metadata?.result?.PartnerParams?.FirstName || '--'}
                              </p>
                            ) : (
                              <p>{complianceData?.user?.firstName}</p>
                            )}
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Lastname</h3>
                            {selectedItem?.metadata?.result?.PartnerParams?.LastName ? (
                              <p>
                                {selectedItem?.metadata?.result?.PartnerParams?.LastName || '--'}
                              </p>
                            ) : (
                              <p>
                                {complianceData?.user?.lastName}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Middlename</h3>
                            <p>
                              {selectedItem?.metadata?.result?.PartnerParams?.MiddleName || '--'}
                            </p>
                          </div>
                        </div>
                        {/* <div className="item">
                            <div className="label-view">
                              <h3>Bank</h3>
                              <p className="name">{detail?.bank || '--'}</p>
                            </div>
                          </div> */}
                      </FormData>
                      <FormData>
                        <div className="item">
                          <div className="label-view">
                            <h3>Date of Birth</h3>
                            <p>{selectedItem?.metadata?.result?.Actions?.DOB || '--'}</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Gender</h3>
                            <p>{selectedItem?.metadata?.result?.Actions?.Gender || '--'}</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>ID Verification</h3>
                            <p>
                              {selectedItem?.metadata?.result?.Actions?.ID_Verification || '--'}
                            </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Phone Number</h3>
                            <p>{selectedItem?.metadata?.result?.Actions?.Phone_Number || '--'}</p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Verify ID Number</h3>
                            <p>
                              {selectedItem?.metadata?.result?.Actions?.Verify_ID_Number || '--'}
                            </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Date Created</h3>
                            <p>
                              {selectedItem?.createdAt
                                ? formatInTimeZone(
                                    new Date(selectedItem?.createdAt),
                                    'UTC',
                                    'do MMM, yyyy, h:mm aa'
                                  )
                                : '--'}
                            </p>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label-view">
                            <h3>Date Updated</h3>
                            <p>
                              {selectedItem?.updatedAt
                                ? formatInTimeZone(
                                    new Date(selectedItem?.updatedAt),
                                    'UTC',
                                    'do MMM, yyyy, h:mm aa'
                                  )
                                : '--'}
                            </p>
                          </div>
                        </div>
                      </FormData>
                    </DataView>
                  ) : (
                    <div className="selfie">
                      <DataView>
                        <FormData>
                          <div className="item">
                            <div className="label-view">
                              <h3>Job Id</h3>
                              <p>{selectedItem?.metadata?.result?.PartnerParams?.job_id || '--'}</p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Verification Type</h3>
                              <p>{selectedItem?.type || '--'}</p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Status</h3>
                              <Status status={selectedItem?.status}>
                                {selectedItem?.status || '--'}
                              </Status>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Confidence Value</h3>
                              <p>{selectedItem?.metadata?.result?.ConfidenceValue || '--'}%</p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Verify ID Number</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions?.Verify_ID_Number || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Human Review Compare</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions?.Human_Review_Compare ||
                                  '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Human Review Liveness Check</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions
                                  ?.Human_Review_Liveness_Check || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Human Review Selfie Check</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions
                                  ?.Human_Review_Selfie_Check || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Human Review Update Selfie</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions
                                  ?.Human_Review_Update_Selfie || '--'}
                              </p>
                            </div>
                          </div>
                        </FormData>
                        <FormData>
                          <div className="item">
                            <div className="label-view">
                              <h3>Liveness Check</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions?.Liveness_Check || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Register Selfie</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions?.Register_Selfie || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Return Personal Info</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions?.Return_Personal_Info ||
                                  '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Selfie Check</h3>
                              <p>{selectedItem?.metadata?.result?.Actions?.Selfie_Check || '--'}</p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Selfie Provided</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions?.Selfie_Provided || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Selfie To ID Authority Compare</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions
                                  ?.Selfie_To_ID_Authority_Compare || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Selfie To ID Cardy Compare</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions
                                  ?.Selfie_To_ID_Card_Compare || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Selfie To Registered Selfie Compare</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions
                                  ?.Selfie_To_Registered_Selfie_Compare || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="item">
                            <div className="label-view">
                              <h3>Update Registered Selfie On File</h3>
                              <p>
                                {selectedItem?.metadata?.result?.Actions
                                  ?.Update_Registered_Selfie_On_File || '--'}
                              </p>
                            </div>
                          </div>
                        </FormData>
                      </DataView>
                      {!selectedItem.type.includes('BVN') && (
                        <div className="compare">
                          <div className="img-wrapper">
                            <p className="title">BVN Selfie</p>
                            {bvnImage ? (
                              <img src={bvnImage} alt="BVN Selfie" className="img" />
                            ) : (
                              <p>No BVN image available</p>
                            )}
                          </div>
                          <div className="img-wrapper">
                            <p className="title">KYC Selfie</p>
                            {selectedItem?.metadata?.image_links?.selfie_image.isLoadiing ? (
                              <Oval width={20} height={20} strokeWidth={4} />
                            ) : selectedItem?.metadata?.image_links?.selfie_image ? (
                              <img
                                src={selectedItem.metadata.image_links.selfie_image}
                                alt="KYC Selfie"
                                className="img"
                              />
                            ) : (
                              <p>No KYC selfie available</p>
                            )}
                          </div>
                        </div>
                      )}

                      {/* <div className="compare">
                        <div className="img-wrapper">
                          <p className="title">Selfie from NIN</p>
                          <img src="" alt="" className="img" />
                        </div>
                        <div className="img-wrapper">
                          <p className="title">Selfie from KYC Verification</p>
                          <img
                            src={selectedItem?.metadata?.image_links?.selfie_image}
                            alt=""
                            className="img"
                          />
                        </div>
                      </div> */}
                    </div>
                  )}
                </Content>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
};

export default VerificationDetailsModal;

VerificationDetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  complianceId: PropTypes.string.isRequired
};

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'VERIFIED':
        return '#16A34A';
      case 'PENDING':
        return '#F4B740';
      case 'FAILED':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 70vw;
    padding: 30px 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 6px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
`;

const HeaderView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #f3f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  .info {
    width: 100%;
    background-color: ${(props) => props.theme.colors?.warningBackground};
    border-radius: 6px;
    padding: 14px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }

  .selfie {
    .compare {
      gap: 3rem;
      width: 100%;
      display: flex;
      /* align-items: center; */
      justify-content: center;
      margin-top: 30px;
      max-width: 100%;

      .img-wrapper {
        width: 500px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .title {
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }

        .img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .details {
        width: 500px;
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .title {
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }
    }
  }

  .input-view {
    width: 100%;
    margin-top: 30px;

    label {
      font-size: 0.875rem;
    }

    .error-label {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.error_200};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .approve {
      width: 120px;
      height: 40px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }
`;

// const DataView = styled.div`
//   width: 80%;
//   background-color: ${({ theme }) => theme.colors?.white};
//   display: flex;
//   justify-content: space-between;
//   gap: 1rem;
// `;

// const FormData = styled.div`
//   width: 100%;
//   background-color: ${({ theme }) => theme.colors?.white};
//   border-radius: 6px;
//   padding: 5px 20px;

//   .title {
//     margin-top: 2rem;
//     margin-bottom: 1rem;

//     h2 {
//       font-size: 1.5rem;
//       font-weight: 600;
//       color: ${({ theme }) => theme.colors?.secondary};
//     }
//   }

//   .item-wrapper {
//     width: 100%;
//     display: flex;
//     gap: 7rem;
//     justify-content: space-between;

//     .label-view {
//       flex: 1;
//       /* width: 70%; */
//       display: flex;
//       justify-content: center;
//       flex-direction: column;
//       color: ${({ theme }) => theme?.colors?.secondary};
//       border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

//       .last {
//         width: 50%;
//       }

//       h3 {
//         font-size: 0.875rem;
//         font-weight: 500;
//         color: ${({ theme }) => theme?.colors?.secondary};
//         margin-bottom: 8px;
//       }

//       p {
//         font-size: 0.875rem;
//         font-weight: 600;
//       }

//       .capitalize {
//         text-transform: capitalize;
//       }
//     }

//     .item {
//       width: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       padding: 5px 0;

//       .copy-view {
//         width: 120px;
//         display: flex;
//         align-items: center;

//         button,
//         a {
//           width: 100%;
//           height: 40px;
//           display: flex;
//           align-items: center;
//           justify-content: flex-end;
//           color: ${({ theme }) => theme?.colors?.info};
//           font-size: 0.875rem;

//           svg {
//             margin-left: 5px;
//             width: 16px;
//             height: 16px;

//             path {
//               stroke: ${({ theme }) => theme?.colors?.info};
//             }
//           }
//         }
//       }
//     }
//   }

//   .last {
//     border-bottom: none;
//     padding-bottom: 0;
//   }
// `;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const FormData = styled.div`
  height: 100%;
  width: 50%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 80%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .name {
        text-transform: capitalize;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
