import { fetchProviders } from '@/api/providersApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { useSortableData } from '@/hooks/useSortableData';
import useOnClickOutside from '@/hooks/useClickOutside';
import { motion, AnimatePresence } from 'framer-motion';
import { formatInTimeZone } from 'date-fns-tz';
import TableLoader from '@/loaders/TableLoader';
import { deleteSystemNotification, fetchSystemNotifications } from '@/api/notificationApi';
import Button from '@/components/Button';
import CreateNotificationsModal from './CreateNotificationsModal';
import { usePermissions } from '@/hooks/usePermissions';
import UpdateNotificationsModal from './UpdateNotificationsModal';
import UpdateNotificationStatusModal from './UpdateNotificationStatus';
import PromptModal from '@/components/PromptModal';
import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as NextIcon } from '@/assets/icons/nav-right.icon.svg';
import { ReactComponent as PrevIcon } from '@/assets/icons/nav-left.icon.svg';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import TableRowsSelect from '@/components/TableRowSelect';

const NotificationsTable = () => {
  const PAGE_LIMIT = 10;
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canCreateSettings = hasPermission('settings', 'create');
  const canUpdateSettings = hasPermission('settings', 'update');
  const canDeleteSettings = hasPermission('settings', 'delete');

  const handleCreate = () => {
    const isAllowed = canCreateSettings || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setShowCreateModal(true);
    }
  };

  const handleUpdate = (obj) => {
    const isAllowed = canUpdateSettings || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setSelectedItem(obj);
      setShowUpdateModal(true);
    }
  };

  const handleUpdateStatus = (obj) => {
    const isAllowed = canUpdateSettings || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setSelectedItem(obj);
      setShowStatusModal(true);
    }
  };

  const handleDelete = (obj) => {
    const isAllowed = canDeleteSettings || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to create');
      return;
    } else {
      setSelectedItem(obj);
      setShowDeleteModal(true);
    }
  };

  const notifications = useQuery({
    queryKey: ['notifications', { page, limit: PAGE_LIMIT }],
    queryFn: fetchSystemNotifications
  });

  const handleDeleteAccouncement = useMutation({
    mutationFn: ({ id }) => deleteSystemNotification(id),
    onSuccess: () => {
      setSelectedItem(null);
      setShowDeleteModal(false);
      queryClient.invalidateQueries(['notifications']);
      toast.success('Accouncement deleted successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const { items, requestSort, sortConfig } = useSortableData(
    notifications?.data?.data?.notifications || []
  );

  const handleRowsPerPageChange = (value) => {
    setSearchParams({ page, limit: value });
    console.log(value);
  };

  // Fetch next page
  const fetchNextPage = () => {
    const totalPage = Math.ceil(notifications?.data?.data?.meta?.totalItems / PAGE_LIMIT);
    // const totalPage = Math.ceil(users?.data?.data?.meta?.totalItems / PAGE_LIMIT);
    if (page < totalPage) {
      setPage(page + 1);
      // setSearchParams({ page: page + 1, limit });
      // navigate(`/users?page=${page + 1}&limit=${limit}`);
    }
  };

  // Fetch previous page
  const fetchPrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      // setSearchParams({ page: page - 1, limit });
      // navigate(`/users?page=${page - 1}&limit=${limit}`);
    }
  };

  if (notifications.isFetching) {
    return <TableLoader />;
  }

  return (
    <NotificationsTableView>
      <TableHeader>
        <Button
          width="20%"
          height="48px"
          type="button"
          style={{ padding: '0 20px' }}
          onClick={() => {
            handleCreate();
          }}>
          Create Notification
        </Button>
      </TableHeader>
      <TableHead>
        <div className>
          <span>Type</span>
        </div>
        <div className="type">
          <span>Message</span>
        </div>
        <div>
          <span>Status</span>
        </div>
        <div>
          <span>Date Created</span>
        </div>
        {/* <div>
          <span>Date Updated</span>
        </div> */}
        <div className="actions"></div>
      </TableHead>
      <TableBody>
        {items.map((item, i) => {
          return (
            <div key={item?.id} className="item">
              <div className>
                <span>{item?.type || '-'}</span>
              </div>
              <div
                className="type"
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap', // Keeps text on one line
                  textOverflow: 'ellipsis'
                }}>
                <span>{item?.message || '-'}</span>
              </div>
              <div>
                <Status active={item?.active}>
                  <span className="dot"></span> {item?.active ? 'Active' : 'Inactive'}
                </Status>
              </div>
              <div>
                <span>{formatInTimeZone(new Date(item?.createdAt), 'UTC', 'do MMM, yyyy')}</span>
                <p>{formatInTimeZone(new Date(item?.createdAt), 'UTC', 'h:mm aa')}</p>
              </div>
              {/* <div>
                <span>{formatInTimeZone(new Date(item?.updatedAt), 'UTC', 'do MMM, yyyy')}</span>
                <p>{formatInTimeZone(new Date(item?.updatedAt), 'UTC', 'h:mm aa')}</p>
              </div> */}

              <div className="actions">
                <button
                  className="menu-icon"
                  onClick={() => {
                    setShowMenu(true);
                    setCurrentIndex(i);
                  }}>
                  <MoreIcon />
                </button>

                <AnimatePresence>
                  {showMenu && currentIndex === i ? (
                    <motion.div
                      className="menu-actions"
                      ref={ref}
                      initial={{ y: -20 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}>
                      <button
                        onClick={() => {
                          handleUpdate(item);
                        }}>
                        <span>Update Notification</span>
                      </button>
                      <button
                        onClick={() => {
                          handleUpdateStatus(item);
                        }}>
                        <span>Update Status</span>
                      </button>
                      <button
                        onClick={() => {
                          handleDelete(item);
                        }}>
                        <span>Delete Notification</span>
                      </button>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
            </div>
          );
        })}
      </TableBody>

      {/* ===== Pagination ====== */}
      {notifications?.data?.data?.meta?.totalItems > 0 && (
        <PaginationView>
          <div></div>
          {/* <TableRowsSelect onChange={handleRowsPerPageChange} /> */}
          <div className="paginate">
            <button className="prev" onClick={fetchPrevPage}>
              <PrevIcon />
            </button>
            <span>
              Page {page} of {Math.ceil(notifications?.data?.data?.meta?.totalItems / PAGE_LIMIT)}
              {/* Page {page} of {Math.ceil(users?.data?.data?.meta?.totalItems / PAGE_LIMIT)} */}
            </span>
            <button className="next" onClick={fetchNextPage}>
              <NextIcon />
            </button>
          </div>
        </PaginationView>
      )}

      <CreateNotificationsModal showModal={showCreateModal} setShowModal={setShowCreateModal} />

      <UpdateNotificationsModal
        selectedItem={selectedItem}
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
      />

      <UpdateNotificationStatusModal
        selectedItem={selectedItem}
        showModal={showStatusModal}
        setShowModal={setShowStatusModal}
      />

      <PromptModal showModal={showDeleteModal}>
        <div className="header">
          <button type="button" onClick={() => setShowDeleteModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete Announcement</h3>
          <p>Do you really want to delete this announcement?</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteAccouncement.mutate({ id: selectedItem?.id })}>
            {handleDeleteAccouncement.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
    </NotificationsTableView>
  );
};

export default NotificationsTable;

const NotificationsTableView = styled.div`
  width: 100%;
  height: 100%;
`;

const getStatusColor = (active) => {
  switch (active) {
    case true:
      return '#16A34A';
    case 'initiated':
      return '#F4B740';
    case false:
      return '#B91C1C';

    default:
  }
};

const Status = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: ${({ active }) => getStatusColor(active)};

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: ${({ active }) => getStatusColor(active)};
  }
`;

const TableView = styled.div`
  width: 100%;
  height: 100%;
`;

const TableHeader = styled.div`
  /* width: 100%; */
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.line};
  border-left: none;
  border-top: none;
  border-right: none;
  /* margin-top: 10px; */
`;

const TableHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.line};
  border-top: 1px solid ${({ theme }) => theme.colors.line};

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 100%;

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.black};
      /* text-transform: uppercase; */
    }

    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
      width: 10px;
    }
  }

  .ascending {
    color: ${({ theme }) => theme.colors.black};

    .up path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .descending {
    color: ${({ theme }) => theme.colors.black};

    .down path {
      stroke: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .amount {
    flex: 0.8;
  }

  .year {
    flex: 0.8;
  }

  .reg {
    flex: 0.6;
  }

  .tin {
    flex: 0.6;
  }

  .status {
    flex: 0.5;
  }

  .date {
    flex: 0.5;
  }

  .actions {
    flex: 0.5;
  }
`;

const TableBody = styled.div`
  width: 100%;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    div {
      flex: 1;
      flex-wrap: wrap;

      span {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }

      p {
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.secondary};
        text-transform: capitalize;
      }
    }

    .amount {
      flex: 0.8;
    }

    .year {
      flex: 0.8;
    }

    .reg {
      flex: 0.6;
    }

    .tin {
      flex: 0.6;
    }

    .status {
      flex: 0.5;

      .verified {
        color: ${({ theme }) => theme.colors?.success};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.success};
          margin-right: 4px;
        }
      }

      .pending {
        color: ${({ theme }) => theme.colors?.warning};
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.75rem;
        text-transform: capitalize;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.warning};
          margin-right: 4px;
        }
      }
    }

    .date {
      flex: 0.5;
    }

    .actions {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      svg {
        cursor: pointer;

        path {
          stroke: ${({ theme }) => theme.colors?.secondary};
        }
      }

      .menu-actions {
        position: absolute;
        top: 22px;
        right: 0px;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow:
          0px 0px 2px rgba(125, 125, 125, 0.1),
          0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        z-index: 10;
        width: 180px;
        border: none;

        button {
          width: 100%;
          padding: 16px 12px;
          font-size: 0.875rem;
          background-color: transparent;
          transition: all 0.2s ease-in-out;
          border: none;
          outline: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.colors.layer3};
          }

          span {
            color: ${({ theme }) => theme.colors.activeTitle};
            width: calc(100% - 35px);
            display: flex;
            justify-content: flex-start;
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
  }
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    button {
      width: 40px;
      height: 40px;
      border: none;
      outline: none;
      background-color: ${({ theme }) => theme.colors?.layer3};
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.layer2};
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;
