import { fetchTransaction } from '@/api/transactionApi';
import { useQuery } from '@tanstack/react-query';

import PageHeader from '@/components/PageHeader';
import styled from 'styled-components';
import PageLoader from '@/loaders/PageLoader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as ExtLinkIcon } from '@/assets/icons/ext-link.icon.svg';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { numberWithCommas } from '@/utils/helpers';
import { format } from 'date-fns';
import { fetchTransfer } from '@/api/transfersApi';
import { formatInTimeZone } from 'date-fns-tz';
import { useState } from 'react';

const getColor = (status) => {
  switch (status) {
    case 'initiated':
      return 'blue';
    case 'processing':
      return '#F4B740';
    case 'failed':
      return '#B91C1C';
    case 'successful':
      return '#16A34A';
    default:
      return '#ccc';
  }
};

const TransferDetails = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();

  const transfer = useQuery({
    queryKey: ['transfer', { id, type: 'all' }],
    queryFn: fetchTransfer
  });

  const [currentDetailPage, setCurrentDetailPage] = useState(1);

  const params = new URLSearchParams(search);
  const currentPage = params.get('page');

  const info = transfer?.data?.data;

  const handleReturn = () => {
    window.history.back();
  };

  if (transfer.isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader title="Transfer Details" subtitle="View and manage transfer" />
      <TransferDetailsView>
        <div className="return" onClick={handleReturn}>
          <BackIcon />
        </div>
        <DataView>
          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>Transfer Status</h3>
                <Status status={info?.status}>{info?.status || '--'}</Status>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Amount</h3>
                <p>₦{numberWithCommas(info?.amount || '00.00')}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Reference No.</h3>
                <p>{info?.reference || info?.identifier || '--'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Remark</h3>
                <p>{info?.providerResponse?.responseMessage || '--'}</p>
              </div>
            </div>

            <div className="item">
              <div className="label-view">
                <h3>Recipient Name</h3>
                <p>{info?.receiverName || info?.accountName || '-'}</p>
              </div>
            </div>

            <div className="item">
              <div className="label-view">
                <h3>Recipient Account Number</h3>
                <p>{info?.receiverAccountNumber || info?.accountNumber || '-'}</p>
              </div>
            </div>

            <div className="item">
              <div className="label-view">
                <h3>Recipient Bank</h3>
                <p>{info?.receiverBank || info?.bankName || '-'}</p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Created</h3>
                <p>
                  {formatInTimeZone(new Date(info?.createdAt), 'UTC', 'do MMM, yyyy, h:mm aa') ||
                    '--'}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Date Updated</h3>
                <p>
                  {formatInTimeZone(new Date(info?.updatedAt), 'UTC', 'do MMM, yyyy, h:mm aa') ||
                    '--'}
                </p>
              </div>
            </div>
            {/* <div className="item">
              <div className="label-view">
                <h3>Date Processed</h3>
                <p>
                  {formatInTimeZone(new Date(info?.processedAt), 'UTC', 'do MMM, yyyy, h:mm aa') ||
                    '--'}
                </p>
              </div>
            </div> */}
          </FormData>

          <FormData>
            <div className="item">
              <div className="label-view">
                <h3>User Id</h3>
                <p>{info?.wallet?.user?.id || info?.user?.id}</p>
              </div>
              <div className="copy-view">
                <button
                  onClick={() => {
                    navigate(`/users/${info?.wallet?.user?.id || info?.user?.id}`);
                  }}>
                  View <ExtLinkIcon />
                </button>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Fullname</h3>
                <p>
                  {info?.wallet?.user
                    ? `${info.wallet.user.firstName} ${info.wallet.user.lastName}`
                    : `${info?.user?.firstName || ''} ${info?.user?.lastName || ''}`}
                </p>
              </div>
            </div>
            <div className="item">
              <div className="label-view">
                <h3>Email Address</h3>
                <p>{info?.wallet?.user?.email || info?.user?.email}</p>
              </div>
            </div>

            <TimelineContainer>
              <h3>Transfer Timeline</h3>
              {info?.events?.map((event) => (
                <TimelineEvent key={event.id} color={getColor(event.status)}>
                  <EventStatus>
                    <div
                      style={{
                        backgroundColor: getColor(event.status),
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%'
                      }}></div>
                    <span>{event.status}</span>
                  </EventStatus>
                  <EventDescription>{event.description}</EventDescription>
                  <p>
                    {formatInTimeZone(new Date(event?.createdAt), 'UTC', 'do MMM, yyyy, h:mm aa') ||
                      '--'}
                  </p>
                </TimelineEvent>
              ))}
            </TimelineContainer>
          </FormData>
        </DataView>
      </TransferDetailsView>
    </>
  );
};

export default TransferDetails;

const Status = styled.p`
  color: ${({ status }) => {
    switch (status) {
      case 'successful':
        return '#16A34A';
      case 'pending':
        return '#F4B740';
      case 'processing':
        return '#F4B740';
      case 'initiated':
        return '#F4B740';
      case 'pending':
        return '#F4B740';
      case 'failed':
        return '#B91C1C';
      case 'declined':
        return '#B91C1C';

      default:
    }
  }};
  text-transform: capitalize;
`;

const TransferDetailsView = styled.div`
  width: 82%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  height: 100%;
  padding-top: 120px;
  overflow-x: hidden;
  margin-left: 18%;

  .return {
    width: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

const DataView = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  .col2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* justify-content: space-between; */
  }
`;

const FormData = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors?.white};
  border-radius: 6px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors?.line};

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors?.line};

    .label-view {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: ${({ theme }) => theme?.colors?.secondary};

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: ${({ theme }) => theme?.colors?.secondary};
        margin-bottom: 8px;
      }

      p {
        font-size: 0.875rem;
        font-weight: 600;
      }
    }

    .copy-view {
      width: 120px;
      display: flex;
      align-items: center;

      button,
      a {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: ${({ theme }) => theme?.colors?.info};
        font-size: 0.875rem;

        svg {
          margin-left: 5px;
          width: 16px;
          height: 16px;

          path {
            stroke: ${({ theme }) => theme?.colors?.info};
          }
        }
      }
    }
  }

  .last {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

// const TimelineContainer = styled.div`
//   position: relative;
//   margin: 50px 0;

//   h3 {
//     font-size: 1rem;
//     font-weight: 600;
//     color: ${({ theme }) => theme?.colors?.secondary};
//     margin-bottom: 15px;
//   }
// `;

const TimelineContainer = styled.div`
  position: relative;
  margin: 20px 0 0 0;
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto; /* Enables scrolling */
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px; /* Prevents text from being cut off */

  h3 {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme?.colors?.secondary};
    margin-bottom: 15px;
  }

  /* Optional: Hide scrollbar for a cleaner UI */
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }
`;

const TimelineEvent = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 40px;
  box-sizing: border-box;
  border-left: 2px solid #ccc;

  p {
    font-size: 0.875rem;
    color: #888;
    margin: 0;
    margin-top: 5px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -7.5px;
    background-color: white;
    border: 2.5px solid ${(props) => props.color || '#ccc'};
    border-radius: 50%;
    width: 15px;
    height: 15px;
    top: 15px;
  }

  &:after {
    content: '${(props) => props.time}';
    position: absolute;
    left: -80px;
    top: 10px;
    color: #555;
  }
`;

const EventStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;

  span {
    font-size: 12px;
    color: #888;
    margin-left: 5px;
    text-transform: capitalize;
  }
`;

const EventDescription = styled.p`
  margin: 0;
  color: #333;
`;
