import withAuthentication from '@/hooks/withAuthentication';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import TransferLimitTable from './TransferLimitTable';
import { usePermissions } from '@/hooks/usePermissions';
import { useQuery } from '@tanstack/react-query';
import { fetchTransferLimits } from '@/api/transferLimitApi';
import useOnClickOutside from '@/hooks/useClickOutside';
import TableLoader from '@/loaders/TableLoader';
import { ReactComponent as MoreIcon } from '@/assets/icons/more.icon.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { numberWithCommas } from '@/utils/helpers';
import UpdateDefaultTransferLimitModal from './UpdateDefaultTransferLimitModal';

const TransferLimit = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setShowMenu(false));

  const { hasPermission, superAdmin } = usePermissions();
  const isASuperAdmin = superAdmin(true);
  const canUpdateSettings = hasPermission('settings', 'update');

  const handleUpdate = () => {
    const isAllowed = canUpdateSettings || isASuperAdmin;
    if (!isAllowed) {
      toast.error('You do not have permission to update');
      return;
    } else {
      setShowUpdateModal(true);
    }
  };

  const transferLimit = useQuery({
    queryKey: ['transferLimit'],
    queryFn: fetchTransferLimits
  });

  const limit = transferLimit?.data?.data;

  if (transferLimit.isFetching) {
    return <TableLoader />;
  }

  return (
    <TransferLimitView>
      <TitleView>
        <h3>Transfer Limit</h3>
        <p>
          Manage tranfer limits for wallets by setting a default daily limit
        </p>
      </TitleView>

      <DisplayView>
        <div className="layout">
          {/* <div className="button-container">
            <Button width="22%" height="48px" type="button" onClick={() => handleCreate()}>
              Create Term
            </Button>
          </div> */}
          <div className="display">
            <div className="items">
              <div className="plan">
                <h3>Daily Transfer Limit: ₦{numberWithCommas(limit?.dailyTransferLimit || '00.00')}</h3>
                <div className="actions">
                  <button
                    className="menu-icon"
                    onClick={() => {
                      setShowMenu(true);
                    }}>
                    <MoreIcon />
                  </button>

                  <AnimatePresence>
                    {showMenu ? (
                      <motion.div
                        ref={ref}
                        className="menu-actions"
                        initial={{ y: -20 }}
                        animate={{ y: 0 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}>
                        <button
                          onClick={() => {
                            handleUpdate();
                          }}>
                          <span>Update Limit</span>
                        </button>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DisplayView>

      <UpdateDefaultTransferLimitModal 
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
      />
    </TransferLimitView>
  );
};

export default withAuthentication(TransferLimit);

const TransferLimitView = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const TitleView = styled.div`
  margin-bottom: 30px;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.activeTitle};
    max-width: 50%;
  }
`;

const DisplayView = styled.div`
  width: 100%;
  height: 100%;

  .layout {
    /* border: 1px solid #000; */
    padding: 20px 0;
    display: flex;
    flex-direction: column;

    .button-container {
      display: flex;
      justify-content: flex-end;
      padding: 0 20px;
      margin-bottom: 20px;

      button {
        margin-left: 20px;
        margin-right: 60px;
      }
    }

    .display {
      display: flex;
      gap: 3rem;
      flex-wrap: wrap;
      align-items: center;

      .items {
        width: 45%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid ${({ theme }) => theme.colors.line};

        .plan {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            width: 100%;
            font-size: 1rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.secondary};
          }

          .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            svg {
              cursor: pointer;

              path {
                stroke: ${({ theme }) => theme.colors?.secondary};
              }
            }

            .menu-actions {
              position: absolute;
              top: 22px;
              background-color: ${({ theme }) => theme.colors.white};
              box-shadow:
                0px 0px 2px rgba(125, 125, 125, 0.1),
                0px 2px 10px rgba(0, 0, 0, 0.1);
              border-radius: 10px;
              z-index: 10;
              width: 180px;
              border: none;

              button {
                width: 100%;
                padding: 16px 12px;
                font-size: 0.875rem;
                background-color: transparent;
                transition: all 0.2s ease-in-out;
                border: none;
                outline: none;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &:hover {
                  background-color: ${({ theme }) => theme.colors.layer3};
                }

                span {
                  color: ${({ theme }) => theme.colors.activeTitle};
                  width: calc(100% - 35px);
                  display: flex;
                  justify-content: flex-start;
                  text-align: left;
                  font-weight: 400;
                }

                .delete {
                  color: ${({ theme }) => theme.colors?.error_200};
                }
              }
            }
          }
        }
      }
    }
  }
`;
