import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '@/assets/icons/cancel.icon.svg';
import { ReactComponent as InfoIcon } from '@/assets/icons/info.icon.svg';
import TextArea from '@/components/TextArea';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  approveOrRejectCompliance,
  approveSelfieCompliance,
  fetchCompliance
} from '@/api/complianceApi';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import FilePicker from '@/components/FilePicker';
import FileModal from './FileModal';

const ApproveSelfieModal = ({ showModal, setShowModal, compliance }) => {
  const queryClient = useQueryClient();
  const [action, setAction] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);

  console.log(compliance);

  const handleAction = (e) => {
    setAction(e.target.value);
  };
  // const compliance = useQuery({
  //   queryKey: ['compliance', {id: complianceId}],
  //   queryFn: fetchCompliance
  // })

  const handleSelfieApproval = useMutation({
    mutationFn: ({ id, data }) => approveSelfieCompliance(id, data),
    onSuccess: () => {
      setShowModal(false);
      queryClient.invalidateQueries(['compliance']);

      if (action === 'reject') {
        toast.error('Selfie Verification Rejected');
      } else {
        toast.success('Selfie Verification Approved Successfully');
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    const data = {
      action: action
    }
    handleSelfieApproval.mutate({ id: compliance.user.id, data});
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ModalView
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal-overlay">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 0 }}
              exit={{ y: 100 }}
              transition={{ duration: 0.3 }}
              className="modal"
              onClick={(e) => e.stopPropagation()}>
              <ModalContent>
                <ContentView>
                  <HeaderView>
                    <h3>Approve selfie</h3>
                    <button className="close" onClick={() => setShowModal(false)}>
                      <CloseIcon />
                    </button>
                  </HeaderView>

                  <Content>
                    <div className="info">
                      <div className="icon">
                        <InfoIcon />
                      </div>
                      <p>
                        Ensure that you've confirmed the selfie is an exact match before proceeding
                        to approve
                      </p>
                    </div>

                    <InputView>
                      <LabelView>
                        <label>Action Type</label>
                      </LabelView>

                      <select
                        name="action"
                        value={action}
                        onChange={handleAction}>
                        <option value="">Select an action</option>
                        <option value="approve">Approve</option>
                        <option value="reject">Reject</option>
                      </select>
                    </InputView>

                    <div className="cta">
                      <button className="cancel" onClick={() => setShowModal(false)}>
                        Cancel
                      </button>
                      <button className="approve" onClick={onSubmit}>
                        {handleSelfieApproval.isLoading ? (
                          <span>
                            <Oval
                              color="#FFF"
                              secondaryColor="#ddd"
                              height={20}
                              width={20}
                              strokeWidth={4}
                              ariaLabel="loading"
                            />
                          </span>
                        ) : (
                          'Proceed'
                        )}
                      </button>
                    </div>
                  </Content>
                </ContentView>
              </ModalContent>
            </motion.div>
          </ModalView>
        )}
      </AnimatePresence>

      <FileModal showModal={showFileModal}>
        <div className="header">
          <button type="button" onClick={() => setShowFileModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <div className="img-wrapper">
            <p className="title">Selfie from NIN</p>
            <img src="" alt="" className="img" />
          </div>
          <div className="img-wrapper">
            <p className="title">Selfie from KYC Verification</p>
            <img src="" alt="" className="img" />
          </div>
          {/* <Document file={detail?.user?.compliance[0]?.certificateUrl}>
            <Page width={516} renderTextLayer={false} pageNumber={1} className="img" />
          </Document> */}
        </div>
      </FileModal>
    </>
  );
};

export default ApproveSelfieModal;

ApproveSelfieModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  complianceId: PropTypes.string.isRequired
};

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 480px;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 6px;
    max-height: 90%;
    overflow-y: scroll;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;
`;

const HeaderView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors?.secondary};
  }

  .close {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #f3f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  .info {
    width: 100%;
    background-color: ${(props) => props.theme.colors?.warningBackground};
    border-radius: 6px;
    padding: 14px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: ${(props) => props.theme.colors?.secondary};
    }
  }

  .compare {
    /* margin-top: 2rem;

    .compare-selfie {
      padding: 17px 25px;
      border-radius: 8px;
      color: ${(props) => props.theme.colors?.white};
      background-color: ${(props) => props.theme.colors?.primary};
    } */

    /* .content { */
    gap: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    max-width: 100%;
    /* padding: 0 2rem; */

    .img-wrapper {
      width: 500px;
      border: 1px solid black;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .title {
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }

      .img {
        width: 100%;
      }
    }

    .details {
      width: 500px;
      border: 1px solid black;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .title {
        font-weight: 600;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }

  .input-view {
    width: 100%;
    margin-top: 30px;

    label {
      font-size: 0.875rem;
    }

    .error-label {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.error_200};
      margin-top: 5px;
    }
  }

  .cta {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .approve {
      width: 120px;
      height: 40px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.colors?.primary};
      color: ${(props) => props.theme.colors?.white};
      font-size: 0.9rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-top: 2rem;
  position: relative;

  .input::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    /* margin: 0; */
  }

  /* .currency-input {
    width: 100%;
    height: 64px;
    outline: none;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: 300;
    text-indent: 15px;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
    border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

    &:focus {
      background-color: ${({ theme }) => theme.colors?.white};
      border: 1px solid ${({ theme }) => theme.colors?.activeTitle};
    }

    &:disabled {
      background-color: #f2f2f2;
      color: #999999;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors?.placeholder};
      font-size: 1rem;
      font-weight: 300;
    }
  } */

  select {
    padding: 0 1rem;
    margin-top: 5px;
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    border: none;
    outline: none;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
    padding: 0 20px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.colors?.inputBackground};
  }

  .icon {
    background-color: transparent;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    position: absolute;
    top: 2.85rem;
    right: 12px;
    cursor: pointer;

    svg {
      transition: all 0.3s ease-in-out;
      transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
      transform-origin: 50% 50%;
      width: 20px;
      height: 15px;
    }
  }

  .input {
    position: relative;

    button {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 5px;
    }
  }

  .error-label {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.error_200};
    margin-top: 5px;
  }
`;

const LabelView = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 0px;

  label {
    font-size: 1rem;
  }
`;