import api from "./api"

export const createSystemNotification = async (data) => {
  const response = await api.post(`/setting/system/notification`, data);
  return response.data;
}

export const updateSystemNotification = async (data) => {
  const response = await api.post(`/setting/system/notification`, data);
  return response.data;
}

export const updateNotificationStatus = async (id, data) => {
  const response = await api.patch(`/setting/system/notification/status/${id}`, data);
  return response.data;
}

export const fetchSystemNotifications = async ({ queryKey }) => {
  const [, { page, limit }] = queryKey;
  const response = await api.get(`/setting/system/notifications?page=${page}&limit=${limit}`);
  return response.data;
}

export const fetchSingleNotification = async ({ queryKey }) => {
  const [, id] = queryKey;
  if (id) {
    const response = await api.get(`/setting/system/notification/${id}`);
    return response.data;
  } else {
    throw new Error('No ID provided');
  }
}

export const deleteSystemNotification = async (id) => {
  const response = await api.delete(`/setting/system/notification/${id}`);
  return response.data;
}