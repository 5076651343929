import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import OutflowsTable from './OutflowsTable';
import InflowsTable from './InflowsTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AllTransfersTable from './AllTransfersTable';

export const TransferStateTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const navigate = useNavigate();

  const tabs = [
    // {
    //   label: 'All',
    //   path: 'all',
    //   value: 'all',
    // },
    {
      label: 'Outflows',
      path: 'outflows',
      value: 'outflows',
    },
    {
      label: 'Inflows',
      path: 'inflows',
      value: 'inflows',
    }
  ];

  useEffect(() => {
    // Check if 'tab' parameter is not present in the URL
    if (!searchParams.get('tab')) {
      // Set the default URL parameters for the "Subscription Plans" tab
      setSearchParams('tab=outflows');
      // Optionally, you can also navigate to the new URL
      navigate('?tab=outflows');
    }
  }, [searchParams, navigate, setSearchParams]);

  return (
    <Tab>
      <TabView>
        {tabs.map((item, index) => (
          <button
            key={index}
            className={`tab ${tab === item.value ? 'active' : ''}`}
            onClick={() => setSearchParams(`tab=${item.path}`)}>
            {item.label}
          </button>
        ))}
      </TabView>
      <TabContent>
        {/* {tabs[activeTab].content} */}
        {/* {tab === 'all' && <AllTransfersTable />} */}
        {tab === 'outflows' && <OutflowsTable />}
        {tab === 'inflows' && <InflowsTable />}
      </TabContent>
    </Tab>
  );
};

const Tab = styled.div`
  width: 100%;
  height: 100%;
`;

const TabView = styled.div`
  height: 50px;
  display: flex;
  z-index: 10;
  margin-left: -30px;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors?.white};
  border-bottom: 1px solid ${(props) => props.theme?.colors?.line};

  button {
    padding: 16px 32px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
      color: ${(props) => props.theme?.colors?.secondary};
    }
  }

  .active {
    color: ${(props) => props.theme?.colors?.secondary};
    border-bottom: 2px solid ${(props) => props.theme?.colors?.secondary};
  }
`;

const TabContent = styled.div`
  width: 100%;
  height: 100%;
`;
